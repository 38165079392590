h2 {
  font-size: 3.5rem !important;
  font-weight: 900;
  color: #192440;
}

img {
  display: block;
  max-width: 100%;
}

.faded {
  color: #666;
}

.larger {
  font-size: 1.3rem;
}

.badges {
  display: inline-block;
  vertical-align: baseline;

  img {
    display: inline-block;
    margin: 0 0.1em;
  }
}

a,
button {
  cursor: pointer;
}

.atlas-cta {
  border-radius: 22px !important;
  padding: 12px 30px !important;
  font-weight: 700;
  transition: .3s ease-in-out !important;
  margin-right: 1em !important;

  &:last-child {
    margin-right: 0em !important;
  }

  &:hover {
    text-decoration: none;
  }
}

.cta-green {
  background: $docs-color-primary;
  color: $docs-color-secondary;

  &:hover {
    color: $docs-color-secondary;
    background: lighten($docs-color-primary, 40%);
  }
}

.cta-blue {
  margin: 1.5rem 0;
  background: $docs-color-secondary;
  color: #fff;

  &:hover {
    color: #fff;
    background: lighten($docs-color-secondary, 20%);
  }
}

.cta-ghost {
  border: 2px solid $docs-color-secondary !important;
  color: $docs-color-secondary;

  &:hover {
    color: #fff;
    background: lighten($docs-color-secondary, 20%);
  }
}

p {
  font-weight: 400;
  line-height: 1.5;
}

img {
  vertical-align: middle;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  &.companies {
    align-items: center;
    justify-content: space-evenly;
    margin-top: 2em;

    div {
      margin: 2em;

      img {
        filter: grayscale(50%);
        opacity: 0.8;

        &:hover {
          filter: grayscale(0%);
          opacity: 1;
        }
      }
    }
  }

  .cell {
    box-sizing: border-box;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    text-align: center;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @media (max-width: $docs-tablet-brk) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    img {
      margin: auto;
    }

    h4 {
      font-size: 1.7rem;
      margin: 0 0 0.5rem 0;
      font-weight: 500;
      line-height: 1.2;
    }

    p {
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
  }
}

.banner {
  padding: 4rem 0;
  border-radius: 0;
  margin-bottom: 0;
  background-size: cover;
  background-image: url(../assets/banner.jpg);
  text-align: left;

  header {
    overflow: hidden;

    img {
      max-width: 100px;
    }
  }

  h1 {
    color: white;
    font-weight: 700;
    margin: 1rem 0 2rem;
    line-height: 1.2;
    font-size: 4.5rem;
  }

  p {
    color: white;
    margin: 1.5rem 0;
    font-size: 1.25rem;
    font-weight: 300;
  }

  .btn {
    margin: 1.5rem 0;
  }

  @media (max-width: $docs-mobile-brk) {
    padding: 4rem 2rem;
  }

  @media (max-width: $docs-mobile-brk) {
    h1 {
        font-size: 3.6rem;
    }
  }
}

.container-section {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.features {
  padding: 0;

  &.intro {
    background: $docs-color-primary;
    background: linear-gradient(168deg, #fff 40%, $docs-color-primary 0);
  }

  &.outro {
    background: #fff;
    background: linear-gradient(168deg, $docs-color-primary 60%, #fff 0);
  }

  .container {
    padding: 2rem 1rem;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    p {
      margin: 1.5rem 0;
    }

    .child {
      flex: 0 0 50%;
      max-width: 50%;
      box-sizing: border-box;
      align-self: center;
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;

      @media (max-width: $docs-tablet-brk) {
        flex: 1;
        max-width: 100%;
      }
    }
  }

  .container-right {
    text-align: right;
    flex-direction: row-reverse;

    @media (max-width: $docs-tablet-brk) {
      text-align: center;
      flex-direction: column-reverse;
    }
  }

  .container-left {
    text-align: left;
    flex-direction: row;

    @media (max-width: $docs-tablet-brk) {
      text-align: center;
      flex-direction: column-reverse;
    }
  }

  .image {
    max-width: 480px;

    img {
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

.copyright {
  background: $docs-color-secondary;
  padding: 2rem 3rem;

  .social-media {
    a {
      width: 40px;
      height: 40px;
      border-radius: 99%;
      background: #fff;
      transition: .4s ease;
      margin-left: 1rem !important;

      i {
        color: $docs-color-secondary;
        font-size: 1.2rem;
        line-height: 40px;
      }

      &:hover {
        background: $docs-color-primary;

        i {
          color: #fff;
        }
      }
    }
  }

  .imprint {
    color: $docs-color-primary;
  }

  .fa-heart {
    color: $docs-color-heart;
  }
}

@keyframes octocat-wave {
  0%, 100% {
    transform: rotate(0);
  }
  20%, 60% {
    transform: rotate(-25deg);
  }
  40%, 80% {
    transform: rotate(10deg);
  }
}

.github-corner {
  svg {
    fill: $docs-color-primary;
    color: $docs-color-secondary;
    position: absolute;
    top: 0;
    border: 0;
    right: 0;
  }

  .octo-arm {
    transform-origin: 130px 106px;
  }

  &:hover .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }

  @media (max-width:500px) {
    &:hover .octo-arm {
      animation: none;
    }

    .octo-arm {
      animation: octocat-wave 560ms ease-in-out;
    }
  }
}

.testimonials {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-height: 660px;
  overflow: hidden;
  justify-content: space-evenly;
  margin-top: 2em;
}

.testimonial {
  flex-basis: 344px;
  max-width: 344px;
  padding: 0.5rem;
  box-sizing: content-box;

  .testimonial-item {
    background: $docs-color-secondary;
    padding: 1.5rem 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;

    .testimonial-item-content {
      color: white;
      flex-grow: 1;
      -webkit-font-smoothing: antialiased;

      p {
        text-align: left;
        font-size: 1.25rem;
        line-height: 1.75rem;
        letter-spacing: -.1px;
      }

      &::before {
        content: "";
        display: block;
        width: 1.5rem;
        height: 1rem;
        margin-top: 0.75rem;
        margin-bottom: 1rem;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2218%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%2013.481c0-2.34.611-4.761%201.833-7.263C3.056%203.716%204.733%201.643%206.865%200L11%202.689C9.726%204.382%208.777%206.093%208.152%207.824c-.624%201.73-.936%203.578-.936%205.545V18H0v-4.519zm13%200c0-2.34.611-4.761%201.833-7.263%201.223-2.502%202.9-4.575%205.032-6.218L24%202.689c-1.274%201.693-2.223%203.404-2.848%205.135-.624%201.73-.936%203.578-.936%205.545V18H13v-4.519z%22%20fill%3D%22%236163FF%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E);
        background-repeat: no-repeat;
      }
    }

    .testimonial-item-footer {
      margin-top: 2rem;
      position: relative;
      font-weight: 600;
      padding-top: 1.25rem;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -.1px;
      text-align: left;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        height: 1px;
        background: $docs-color-primary;
      }

      .testimonial-item-face {
        display: inline-block;
        border-radius: 100%;
        margin-right: 1em;
        filter: grayscale(70%);
      }

      .testimonial-item-name {
        color: #eceded;

        &:last-child {
          padding-left: 0.5em;
          color: $docs-color-primary;
        }
      }
    }
  }
}

.oss-friends-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  .cell {
    width: 350px;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 1rem;
  }
}
